<template>
  <div class="lobby-list">
    <div class="lobby-item" v-for="lobby in fetchGameCardList" :key="lobby.id">
      <div class="circle" @click="lunchGame(lobby)">
        <img :src="getVendorImage(lobby.vendor)" :alt="lobby.title" class="vendor-image" />
        <img :src="getVendorLogo(lobby.vendor)" :alt="lobby.title" class="vendor-logo" />
      </div>
    </div>
  </div>
</template>

<script>
import {
  BButton,
} from 'bootstrap-vue'
import store from '@/store'
import axios from 'axios'
import { createNamespacedHelpers } from 'vuex'
import { FETCH_GAME_CARD_LIST, FETCH_GAME_CARD_START } from '@/store/settings/action'

const settingsStore = createNamespacedHelpers('settingsStore')

export default {
  components: {
    BButton,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      balance: null,
      lobbies: [],
      groupedLobbiesArray: [],
    }
  },
  computed: {
    ...settingsStore.mapGetters({
      fetchGameCardList: 'fetchGameCardList',
      fetchGameCardStart: 'fetchGameCardStart',
    }),
  },
  mounted() {
    this.$socket.emit('handleMenuEntry', {
      site: this.$site,
      userid: JSON.parse(localStorage.getItem('userData')).userid,
      menu: 'casino',
      menuCategory: '',
    })
    this.fetchCardList()
  },
  beforeDestroy() {
    this.$socket.emit('handleMenuEntry', {
      site: this.$site,
      userid: JSON.parse(localStorage.getItem('userData')).userid,
      menu: '',
      menuCategory: '',
    })
  },
  methods: {
    async init() {
      try {
        const response = await axios.get('https://api.honorlink.org/api/lobby-list', {
          headers: {
            accept: 'application/json',
            Authorization: 'Bearer BNXNlcekSKk9MavaEYosnHwsAjsVDs6ymsTOQKhw',
          },
        })
        this.lobbies = response.data
        // Group by vendor
        const groupedLobbies = {}
        this.lobbies.forEach(lobby => {
          if (this.specificVendors.includes(lobby.vendor) && !groupedLobbies[lobby.vendor]) {
            // if (!groupedLobbies[lobby.vendor]) {
            groupedLobbies[lobby.vendor] = lobby
          }
        })
        this.groupedLobbiesArray = Object.values(groupedLobbies)
        console.log(this.groupedLobbiesArray)
      } catch (error) {
        console.error('Error fetching lobby list:', error)
      }
    },
    ...settingsStore.mapActions({
      $fetchGameCardList: FETCH_GAME_CARD_LIST,
      $fetchGameCardStart: FETCH_GAME_CARD_START,
    }),
    async fetchCardList() {
      await this.$fetchGameCardList({
        site: this.$site,
      })
    },
    getVendorLogo(vendor) {
      try {
        return require(`@/views/Casino-Games/Casino-Logo/${vendor}.png`)
      } catch (error) {
        return require('@/views/Casino-Games/Casino-Logo/default.png')
      }
    },
    getVendorImage(vendor) {
      try {
        return require(`@/views/Casino-Games/Casino-Logo/${vendor}_.png`)
      } catch (error) {
        return require('@/views/Casino-Games/Casino-Logo/default.png')
      }
    },
    async lunchGame(event) {
      const site = this.$site
      const username = site.substring(0, 3).toLowerCase() + this.userData.userid
      // const username = addCode + this.userData.userid
      const nickname = this.userData.userid
      const game_id = event.id
      const vendor = event.vendor
      await this.$fetchGameCardStart({
        username,
        nickname,
        game_id,
        vendor,
      })
      this.$socket.emit('handleMenuEntry', {
        site: this.$site,
        userid: JSON.parse(localStorage.getItem('userData')).userid,
        menu: 'casino',
        menuCategory: event.vendor,
      })
      console.log('$fetchGameCardStart:::', this.fetchGameCardStart)
      // Open the link in a new window
      window.open(this.fetchGameCardStart.link, '_blank')
    },
  },
}
</script>

<style>
.lobby-list {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  justify-content: center;
  padding: 20px;
}

.circle {
  width: 300px;
  height: auto;
  border: thick solid;
  border-radius: 50%;
  background-color: #000000;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.circle:hover .vendor-image {
  transform: translateY(20px);
  transition: transform 0.5s ease;
}

.vendor-logo {
  position: absolute;
  width: 170px;
  padding-top: 170px;
}
/* Responsive styles for mobile devices */
@media (max-width: 768px) {
  .lobby-list {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    justify-content: center;
    padding: 10px;
  }
  .circle {
    width: 150px;
    height: 150px;
    border: solid;
    border-radius: 20%;
    background-color: #000000;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }
  .vendor-image {
    width: 210px;
    padding-top: 70px;
  }
  .vendor-logo {
    position: absolute;
    width: 100px;
    padding-top: 100px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5); /* Adds a shadow around the logo */
    filter: drop-shadow(0 0 10px rgba(0, 0, 0, 0.5)); /* Adds a shadow around the logo */
  }
  .circle:hover .vendor-image {
    transform: translateY(20px);
    transition: transform 0.5s ease;
  }
}
</style>
